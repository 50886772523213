import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import "./Dashboard.css";
import DOMPurify from "dompurify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState("purpose");
  const [formData, setFormData] = useState({});

  // const handleTabClick = (tab) => {
  //   setActiveTab(tab);
  // };

  const handlePurposeNext = (data) => {
    setFormData({ ...formData, ...data });
    setActiveTab("title");
  };

  const handleTitleNext = (data) => {
    setFormData({ ...formData, ...data });
    setActiveTab("outline");
  };

  const handleOutlineNext = (data) => {
    setFormData({ ...formData, ...data });
    setActiveTab("generate");
  };

  async function title_generation(targetAudience, seoKeywords, voice) {
    try {
      const blog_output = await chatgpt(
        `Just Generate 5 blog titles on below information:
        Target Audience: ${targetAudience},
        SEO Keywords: ${seoKeywords},
        Writing Voice: ${voice}.
        Donot add starting or ending line I just want 5 blog titles without numbers at their start. Donot add any special characters or hypen in the start of title. Please Donot add inverted commas in start and end of title.
        '''Note: please donot mention sequence numbers with the blog titles. I want blog titles without numbers. Donot add inverted commas in title'''
        `
      );

      // Split the blog_output string into an array of titles
      const titles = blog_output
        .split("\n")
        .map((title) => title.trim())
        .filter(Boolean);
      console.log(titles);

      return titles;
    } catch (error) {
      console.error("Error fetching titles:", error);
    }
  }

  async function chatgpt(prompt) {
    const DEFAULT_PARAMS = {
      model: "gpt-3.5-turbo-1106",
      messages: [
        {
          role: "system",
          content:
            "You are ChatGPT, a large language model trained by OpenAI. Act as article generator",
        },
        { role: "user", content: prompt },
      ],
    };

    const params = { ...DEFAULT_PARAMS };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + String(process.env.REACT_APP_OPEN_AI_KEY),
      },
      body: JSON.stringify(params),
    };
    const response = await fetch(
      "https://api.openai.com/v1/chat/completions",
      requestOptions
    );
    const data = await response.json();
    const result = data.choices[0].message.content;
    return result;
  }

  const PurposeContent = ({ data, onNext }) => {
    const [targetAudience, setTargetAudience] = useState(
      data.targetAudience || ""
    );
    const [goal, setGoal] = useState(data.goal || "");
    const [postLength, setPostLength] = useState(data.postLength || "");
    const [seoKeywords, setSeoKeywords] = useState(data.seoKeywords || "");
    const [writingVoice, setWritingVoice] = useState(data.writingVoice || "");

    const [selectedGoal, setSelectedGoal] = useState(data.goal || "");
    const [selectedLength, setSelectedLength] = useState(data.postLength || "");
    const [selectedVoice, setSelectedVoice] = useState(data.writingVoice || "");

    const [loading, setLoading] = useState(false);

    const [errors, setErrors] = useState({
      targetAudience: false,
      goal: false,
      postLength: false,
      seoKeywords: false,
      writingVoice: false,
    });

    const handleGoalClick = (selectedGoal) => {
      setGoal(selectedGoal);
      setSelectedGoal(selectedGoal);
      setErrors({ ...errors, goal: false });
    };

    const handleLengthClick = (selectedLength) => {
      setPostLength(selectedLength);
      setSelectedLength(selectedLength);
      setErrors({ ...errors, postLength: false });
    };

    const handleVoiceClick = (selectedVoice) => {
      setWritingVoice(selectedVoice);
      setSelectedVoice(selectedVoice);
      setErrors({ ...errors, writingVoice: false });
    };

    const handleFirstStepNext = async () => {
      let formIsValid = true;
      const requiredFields = {
        targetAudience,
        goal,
        postLength,
        seoKeywords,
        writingVoice,
      };
      const newErrors = { ...errors };

      Object.keys(requiredFields).forEach((field) => {
        if (!requiredFields[field]) {
          formIsValid = false;
          newErrors[field] = true;
        } else {
          newErrors[field] = false;
        }
      });

      setErrors(newErrors);

      if (formIsValid) {
        setLoading(true);

        try {
          const titles1 = await title_generation(
            targetAudience,
            seoKeywords,
            writingVoice
          );

          if (titles1) {
            onNext({
              targetAudience,
              goal,
              postLength,
              seoKeywords,
              writingVoice,
              titles1,
            });
          }
        } catch (error) {
          console.error("Title generation failed:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    return (
      <div className="space-y-4">
        <div>
          <label className="text-gray-600 font-semibold block mb-2">
            Who is the target audience?{" "}
            {errors.targetAudience && (
              <small className="text-red-500">* Required</small>
            )}
          </label>
          <input
            value={targetAudience}
            onChange={(e) => {
              setTargetAudience(e.target.value);
              setErrors({ ...errors, targetAudience: false });
            }}
            className={`border ${
              errors.targetAudience ? "border-red-500" : "border-gray-300"
            } rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline`}
            id="targetAudience"
            type="text"
            placeholder="Who is the target audience?"
          />
        </div>
        <div>
          <label className="text-gray-600 font-semibold block mb-2">
            What's the format of this article{" "}
            {errors.goal && <small className="text-red-500">* Required</small>}
          </label>
          <div className="flex flex-wrap gap-2">
            {[
              "Promotional",
              "Informational",
              "Narrative story",
              "How to",
              "List Form",
              "Comparison",
            ].map((goal) => (
              <button
                key={goal}
                onClick={() => handleGoalClick(goal)}
                className={`border border-gray-300 rounded-md px-4 py-2 text-sm hover:bg-gray-100 focus:outline-none ${
                  selectedGoal === goal ? "border-indigo-500" : ""
                }`}
              >
                {goal}
              </button>
            ))}
          </div>
        </div>
        <div>
          <label className="text-gray-600 font-semibold block mb-2">
            How long should the post be?{" "}
            {errors.postLength && (
              <small className="text-red-500">* Required</small>
            )}
          </label>
          <div className="flex flex-wrap gap-2">
            {[
              "Short (400-600 words)",
              "Medium (600-1000 words)",
              "Large (1000+ words)",
            ].map((length) => (
              <button
                key={length}
                onClick={() => handleLengthClick(length)}
                className={`border border-gray-300 rounded-md px-4 py-2 text-sm hover:bg-gray-100 focus:outline-none ${
                  selectedLength === length ? "border-indigo-500" : ""
                }`}
              >
                {length}
              </button>
            ))}
          </div>
        </div>
        <div>
          <label className="text-gray-600 font-semibold block mb-2">
            What SEO keywords should we include?{" "}
            {errors.seoKeywords && (
              <small className="text-red-500">* Required</small>
            )}
          </label>
          <input
            value={seoKeywords}
            onChange={(e) => {
              setSeoKeywords(e.target.value);
              setErrors({ ...errors, seoKeywords: false });
            }}
            className={`border ${
              errors.seoKeywords ? "border-red-500" : "border-gray-300"
            } rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline`}
            id="seoKeywords"
            type="text"
            placeholder="Use tab, or , separate keywords"
          />
        </div>
        <div>
          <label className="text-gray-600 font-semibold block mb-2">
            What voice do you want us to write in?{" "}
            {errors.writingVoice && (
              <small className="text-red-500">* Required</small>
            )}
          </label>
          <div className="flex flex-wrap gap-2">
            {["Excited", "Professional", "Casual", "Funny", "Authority"].map(
              (writingVoice) => (
                <button
                  key={writingVoice}
                  onClick={() => handleVoiceClick(writingVoice)}
                  className={`border border-gray-300 rounded-md px-4 py-2 text-sm hover:bg-gray-100 focus:outline-none ${
                    selectedVoice === writingVoice ? "border-indigo-500" : ""
                  }`}
                >
                  {writingVoice}
                </button>
              )
            )}
          </div>
        </div>
        {/* <div>
          <label className="text-gray-600 font-semibold block mb-2">
            What voice do you want us to write in?{" "}
            {errors.writingVoice && (
              <small className="text-red-500">* Required</small>
            )}
          </label>
          <input
            value={writingVoice}
            onChange={(e) => {
              setWritingVoice(e.target.value);
              setErrors({ ...errors, writingVoice: false });
            }}
            className={`border ${
              errors.writingVoice ? "border-red-500" : "border-gray-300"
            } rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline`}
            id="writingVoice"
            type="text"
            placeholder="Type here..."
          />
        </div> */}
        <div className="flex justify-end">
          <button
            className="mt-6 bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
            onClick={handleFirstStepNext}
          >
            {loading ? "Generating..." : "Next"}
          </button>
        </div>
      </div>
    );
  };

  const BlogTitle = ({ data, onNext }) => {
    const [loading, setLoading] = useState(false);
    const [outline, setOutline] = useState(false);
    const [finalTitle, setFinalTitle] = useState(false);
    const [selectedTitle, setSelectedTitle] = useState(false);
    const [customTitle, setCustomTitle] = useState("");
    const [error, setError] = useState("");

    const handleTitleClick = (title) => {
      setSelectedTitle(title);
      setCustomTitle(""); // Clear custom title when a predefined title is selected
      setFinalTitle(title);
      setError("");
    };

    const handleCustomTitleChange = (e) => {
      // Clear selected title as soon as the user starts typing in custom title
      setSelectedTitle(null);
      setCustomTitle(e.target.value);
      setFinalTitle(e.target.value);
      setError("");
    };

    const handleChatgpt = async () => {
      if (!selectedTitle && !customTitle) {
        setError("Please select a title or write your own.");
        return;
      }

      setLoading(true); // Set loading to true when starting content generation

      const prompt = `Write a ${data.postLength} word ${data.goal} blog post titled ${finalTitle} in a tone ${data.writingVoice} that is optimized for the keyword ${data.seoKeywords} with tone. This exact keyword should appear at least 10 times in the post. The post should be engaging to an audience of ${data.targetAudience}. Please format the post as HTML but do not include the word "html". Start directly with the first paragraph. Do not begin with "sure here is your blog post". Do not include the blog title in the response. Donot begin with blog title heading in blog post. Please keep in mind the format of the article provided by the user, which is ${data.goal}. If ${data.goal} is in list form, then use <li> tags instead of paragraphs.
`
      // const prompt = `The format of the article is . Apply relevant html tags <h2>, <h3>, <p> to create blog content. Note start your blog post from <p> tag please. It should look like a blog content with proper format. Dont include <head> or <body> tags, just start from heading and paragraph tags. Create a compelling blog post with the title . Tailor the content to resonate with your target audience which is ${data.targetAudience}, considering their needs and interests. Strive for the chosen post length, adhering to the specified range . Seamlessly integrate essential SEO keywords which are , ensuring they are strategically placed throughout the content. Maintain a consistent and engaging writing voice which is ${data.writingVoice}, aligning with the desired tone. Employ captivating language and storytelling techniques to capture and retain your audience's attention. Conclude the post with a concise summary and a compelling call-to-action to encourage reader engagement. Optimize the entire piece with SEO-friendly language to enhance its search engine visibility.
      // `;

      console.log(prompt);

      const blog_output = await chatgpt(prompt);

      setOutline(blog_output);

      setLoading(false); // Set loading to false when content generation is complete

      onNext({
        selectedTitle,
        customTitle,
        finalTitle,
        outline: blog_output,
      });
    };

    let titles = data.titles1 || [];

    return (
      <div className="">
        <h1 className="text-center text-xl font-semibold text-gray-800 mb-6">
          Pick a title for your post, or write your own
        </h1>
        <p className="text-sm text-gray-400 mb-2">
          Note: If you select a title as well as write your own title, your own
          title will be preferred
        </p>
        <div className="">
          {titles.map((title, index) => (
            <div key={index} className="cursor-pointer flex items-center pb-4">
              <input
                type="radio"
                name="title"
                id={`title${index + 1}`}
                className="cursor-pointer text-purple-600 focus:ring-purple-500 h-4 w-4"
                checked={selectedTitle === title}
                onChange={() => handleTitleClick(title)}
              />
              <label
                htmlFor={`title${index + 1}`}
                className={`cursor-pointer rounded-md p-2 border ml-3 font-normal  ${
                  selectedTitle === title
                    ? "text-indigo-500 border-indigo-500 bg-gray-50"
                    : "text-gray-800 border-gray-200"
                }`}
              >
                {title}
              </label>
            </div>
          ))}
        </div>
        <div className="flex items-center mb-4 mt-4">
          <hr className="flex-grow border-t border-gray-300" />
          <span className="mx-4 text-gray-500">or</span>
          <hr className="flex-grow border-t border-gray-300" />
        </div>
        <div className="mt-4">
          <label className="text-gray-600 font-semibold block mb-2">
            Write your own title:
          </label>
          <input
            value={customTitle}
            onChange={handleCustomTitleChange}
            className="border border-gray-300 rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Type your own title here"
          />
        </div>
        {error && <p className="text-red-500 mt-2 text-sm">{error}</p>}

        <div className="flex justify-between items-center mt-8">
          <button
            className="px-4 py-2 rounded-md border border-indigo-500 text-indigo-500 text-sm flex items-center"
            onClick={() => setActiveTab("purpose")}
          >
            <i className="fas fa-arrow-left mr-2"></i>
            Back
          </button>

          <button
            className="bg-gradient-to-r from-indigo-600 via-purple-500 to-pink-500 hover:bg-gradient-to-r hover:from-indigo-500 hover:via-purple-400 hover:to-pink-400 text-white px-4 py-2 rounded-md text-sm flex items-center focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
            onClick={handleChatgpt}
            disabled={loading}
          >
            <div className="flex items-center">
              {!loading && (
                <span className="mr-2">
                  <i className="fa-solid fa-wand-magic-sparkles"></i>
                </span>
              )}
              {loading ? (
                <>
                  <span>Generating</span>
                  <div className="loader ease-linear border border-t-2 border-white-500 border-white h-4 w-4 ml-2 animate-spin rounded-full"></div>
                </>
              ) : (
                <span>Generate with AI</span>
              )}
            </div>
          </button>
        </div>
      </div>
    );
  };

  const BlogOutline = ({ data, onNext }) => {
    const [h1Title, setH1Title] = useState(data?.finalTitle);
    const [body, setBody] = useState(data?.outline);

    const handleThirdStepNext = () => {
      onNext({
        h1Title: h1Title,
        body: body,
      });
    };

    return (
      <div className="">
        <div className="mb-2">
          <label className="text-gray-600 font-semibold block mb-2">
            H1 Title
          </label>
          <textarea
            value={h1Title}
            className="border border-gray-300 rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline"
            id="keywords"
            type="text"
            placeholder=""
            rows={2}
            onChange={(e) => {
              setH1Title(e.target.value);
              data.finalTitle = e.target.value;
            }}
          />
        </div>
        <div className="mb-2">
          <label className="text-gray-600 font-semibold block mb-2">
            Blog Body
          </label>
          <CKEditor
            editor={ClassicEditor}
            data={body}
            onChange={(event, editor) => {
              const bodyData = editor.getData();
              setBody(bodyData);
              data.outline = bodyData;
            }}
          />
        </div>

        <div className="flex justify-between items-center mt-8">
          <button
            className="px-4 py-2 rounded-md border border-indigo-500 text-indigo-500 text-sm flex items-center"
            onClick={() => setActiveTab("title")}
          >
            <i className="fas fa-arrow-left mr-2"></i>
            Back
          </button>
          <button
            className="bg-indigo-600 hover:bg-indigo-500 text-white px-4 py-2 rounded-md text-sm flex items-center focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
            onClick={handleThirdStepNext}
          >
            Next
          </button>
        </div>
      </div>
    );
  };

  const GenerateContent = ({ data }) => (
    <div className="space-y-4">
      <div className="mb-2">
        <h1
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.h1Title) }}
        ></h1>
      </div>
      <div className="mb-2">
        <div
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.body) }}
        />
      </div>
      <div className="flex justify-between items-start mt-8">
        <button
          className="px-4 py-2 rounded-md border border-indigo-500 text-indigo-500 text-sm flex items-center"
          onClick={() => setActiveTab("outline")}
        >
          <i className="fas fa-arrow-left mr-2"></i>
          Back
        </button>
      </div>
    </div>
  );

  const TabWithNumber = ({ number, label, onClick, isActive }) => (
    <button
      onClick={onClick}
      className={`flex items-center space-x-2 py-2 px-4 focus:outline-none ${
        isActive
          ? "text-indigo-600 font-semibold border-b-2 border-indigo-600"
          : "text-gray-600 font-semibold hover:text-indigo-500"
      }`}
    >
      <div className="rounded-full bg-gray-300 h-6 w-6 flex items-center justify-center">
        <span className="text-sm font-bold">{number}</span>
      </div>
      <span>{label}</span>
    </button>
  );

  return (
    <div className="min-h-screen bg-gray-100 py-10 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <div className="bg-white shadow rounded-lg p-6">
          <div className="flex flex-col md:flex-row items-baseline md:items-center justify-between space-x-4 mb-8">
            <TabWithNumber
              number={1}
              label="Purpose & Goals"
              // onClick={() => handleTabClick("purpose")}
              isActive={activeTab === "purpose"}
            />
            <TabWithNumber
              number={2}
              label="Blog Title"
              // onClick={() => handleTabClick("title")}
              isActive={activeTab === "title"}
            />
            <TabWithNumber
              number={3}
              label="Blog Outline"
              // onClick={() => handleTabClick("outline")}
              isActive={activeTab === "outline"}
            />
            <TabWithNumber
              number={4}
              label="Generate Doc"
              // onClick={() => handleTabClick("generate")}
              isActive={activeTab === "generate"}
            />
          </div>
          {activeTab === "purpose" && (
            <PurposeContent data={formData} onNext={handlePurposeNext} />
          )}
          {activeTab === "title" && (
            <BlogTitle data={formData} onNext={handleTitleNext} />
          )}
          {activeTab === "outline" && (
            <BlogOutline data={formData} onNext={handleOutlineNext} />
          )}
          {activeTab === "generate" && <GenerateContent data={formData} />}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
